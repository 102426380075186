import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload
} from '@fortawesome/free-solid-svg-icons'

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Wainwrights Checklist" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1>Wainwrights Checklist</h1>
              <p>Alfred Wainwright ("A.W.") MBE (17 January 1907 – 20 January 1991) was a British fellwalker, guidebook author and illustrator. His seven-volume Pictorial Guide to the Lakeland Fells, published between 1955 and 1966 and consisting entirely of reproductions of his manuscript, has become the standard reference work to 214 of the fells of the English Lake District. Among his 40-odd other books is the first guide to the Coast to Coast Walk, a 192-mile long-distance footpath devised by Wainwright which remains popular today.</p>
              <p>The Wainwright's Checklist is a list of 214 of the Lake District fells from Wainwright's work. It has become a right-of-passage for many fellwalkers to complete all of Wainwright's routes.</p>
              <a href="../wainwright-checklist.pdf" download>
                <h1><FontAwesomeIcon icon={faDownload}/> Download Wainwrights Checklist</h1>
              </a>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
